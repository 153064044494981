import React from "react";
import Favorite from "./Favorite";

class FavoritesList extends React.Component {
  render() {
    const { favorites, handleCityName, deleteFavorite } = this.props;
    return (
      <div className="list-group">
        {favorites.map(fav => (
          <div
            key={fav}
            className="list-group-item py-0 px-2"
            onClick={() => handleCityName(fav)}
          >
            <Favorite
              city={fav}
              handleCityName={() => handleCityName()}
              deleteFavorite={() => deleteFavorite(fav)}
            />
          </div>
        ))}
      </div>
    );
  }
}
export default FavoritesList;
