// @flow

import React from "react";
import { nest } from "d3-collection";
import { timeDay } from "d3-time";
import Forecast from "./Forecast";
import WeatherClient from "../WeatherClient";
import "./ForecastTable.css";

const DAYS = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag"
];

function ForecastTable(props) {
  const forecastByDay = nest()
    .key(forecast => timeDay(new Date(forecast.dt * 1000)).getTime())
    .entries(props.forecast.list);

  return (
    <div className="list-group">
      {forecastByDay.map((value, index, array) => (
        <div key={value.key} className="list-group-item px-0 py-1">
          <h2 className="ForecastTable--header">
            {DAYS[new Date(+value.key).getDay()]}
          </h2>
          <div
            style={{ overflowX: "auto", WebkitOverflowScrolling: "touch" }}
            className={
              "d-flex flex-row" +
              (index === 0 ? " justify-content-end" : "") +
              (index === array.length - 1 ? " justify-content-start" : "")
            }
          >
            {value.values.map(forecast => (
              <Forecast
                key={forecast.dt_txt}
                temp={forecast.main.temp}
                date={`${new Date(forecast.dt * 1000).getHours()} Uhr`}
                img={WeatherClient.weatherIconUrl(forecast.weather[0].icon)}
                description={forecast.weather[0].description}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ForecastTable;
