import React from "react";
import "./Weather.css";
import WeatherClient from "../WeatherClient";

class Favorite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weather: { state: "empty" }
    };
  }

  componentDidMount() {
    WeatherClient.currentWeatherForCityName(this.props.city)
      .then(json => {
        this.setState({ weather: { state: "success", current: json } });
      })
      .catch(error => {
        this.setState({ weather: { state: "error", message: error } });
      });
  }

  render() {
    const { city } = this.props;
    return (
      <div className="Favorite d-flex align-items-center">
        {this.state.weather.state === "success" && (
          <div className="d-flex flex-column align-items-center mr-2">
            <img
              src={WeatherClient.weatherIconUrl(
                this.state.weather.current.weather[0].icon
              )}
              width="50"
              height="50"
              alt={this.state.weather.current.weather[0].description}
              title={this.state.weather.current.weather[0].description}
            />
            <span className="lead">
              {Math.round(this.state.weather.current.main.temp)}&nbsp;°C
            </span>
          </div>
        )}
        <h3 className="h4 mb-0">{city}</h3>
        <div className="flex-grow-1 d-flex justify-content-end">
          <button
            className="btn btn-danger flex-end"
            onClick={() => this.props.deleteFavorite()}
          >
            <i className="fas fa-trash-alt" />
          </button>
        </div>
      </div>
    );
  }
}
export default Favorite;
