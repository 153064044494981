import React from "react";
import "./Weather.css";

function Forecast(props) {
  return (
    <div className="d-flex flex-column align-items-center mx-2">
      <img
        src={props.img}
        width="50"
        height="50"
        alt={props.description}
        title={props.description}
      />
      <p className="Weather--temp">{Math.round(props.temp)}&nbsp;°C</p>
      <p className="Weather--time">{props.date}</p>
    </div>
  );
}

export default Forecast;
