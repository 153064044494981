import React, { PureComponent } from "react";
import WeatherClient from "./WeatherClient";
import ForecastTable from "./components/ForecastTable";
import FavoritesList from "./components/FavoritesList";
import produce from "immer";
import "./App.css";
import moguru from "./moguru.png";

const LAST_LOCATION_KEY = "lastLocation";

class App extends PureComponent {
  constructor(props) {
    super(props);
    let lastLocation = null;
    let favs = [];
    try {
      const storedFavorites = JSON.parse(localStorage.getItem("favorites"));
      if (Array.isArray(storedFavorites)) {
        favs = storedFavorites;
      }
    } catch (e) {}

    try {
      const locationData = localStorage.getItem(LAST_LOCATION_KEY);
      if (locationData != null) {
        lastLocation = JSON.parse(locationData);
      }
    } catch (e) {}
    this.state = {
      weather: { state: "empty" },
      location: lastLocation,
      favorites: favs,
      cityname: null
    };
  }

  componentDidUpdate() {
    localStorage.setItem("favorites", JSON.stringify(this.state.favorites));
  }

  handleChange = event => {
    this.setState({ cityname: event.target.value });
  };
  handleSubmit = event => {
    event.preventDefault();
    this.handleCityNameButton(this.state.cityname);
  };

  updateLocation(location) {
    try {
      const locationData = JSON.stringify({
        latitude: location.latitude,
        longitude: location.longitude
      });
      localStorage.setItem(LAST_LOCATION_KEY, locationData);
    } catch (e) {}
    this.setState({ location });
  }

  loadWeather = request => {
    request
      .then(json => {
        this.setState({ weather: { state: "success", forecast: json } });
      })
      .catch(error => {
        debugger;
        console.log(error);
        this.setState({ weather: { state: "error", message: error } });
        alert(
          "Wetter konnte nicht abgerufen werden. Versuchen Sie eine andere Stadt."
        );
      });
  };

  handleCityNameButton = cityname => {
    this.setState({ weather: { state: "loading" } });
    this.loadWeather(WeatherClient.forecastForCityName(cityname));
  };

  handlePositionButton = () => {
    this.setState({ weather: { state: "loading" } });
    if (this.state.location != null) {
      this.loadWeather(
        WeatherClient.forecastForCoordinate(this.state.location)
      );
    } else {
      navigator.geolocation.getCurrentPosition(position => {
        const { coords } = position;
        this.updateLocation(coords);
        this.loadWeather(WeatherClient.forecastForCoordinate(coords));
      });
    }
  };

  handleFavoriteButton = () => {
    this.saveFavorite(this.state.weather.forecast.city.name);
  };

  saveFavorite = favorite => {
    if (this.state.favorites == null) this.setState({ favorites: [favorite] });
    else if (!this.state.favorites.includes(favorite)) {
      this.setState(
        produce(draft => {
          draft.favorites.push(favorite);
        })
      );
    }
  };

  deleteFavorite = favorite => {
    this.setState(
      produce(draft => {
        draft.favorites.splice(draft.favorites.indexOf(favorite), 1);
      })
    );
  };

  render() {
    const { weather } = this.state;
    return (
      <>
        <nav className="navbar navbar-light bg-light py-0">
          <h1 className="navbar-brand mb-0">
            <img
              src={moguru}
              height="30"
              alt="moguru"
              className="d-inline-block mr-2"
            />
            Wetter
          </h1>
          <form
            onSubmit={this.handleSubmit}
            className="form-inline flex-grow-1 my-2"
          >
            <div className="input-group flex-grow-1">
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                placeholder="Frankfurt, Berlin, …"
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={this.handlePositionButton}
                  disabled={weather.state === "loading"}
                >
                  <i className="fas fa-location-arrow" />
                </button>
              </div>
            </div>
          </form>
        </nav>
        <div className="container py-4">
          <h2 className="text-center">Favoriten</h2>
          <FavoritesList
            favorites={this.state.favorites}
            handleCityName={this.handleCityNameButton}
            deleteFavorite={this.deleteFavorite}
          />
          <div />
          {weather.state === "loading" && (
            <p className="alert alert-warning">Bitte warten…</p>
          )}
          {weather.state === "success" && (
            <div>
              <h2 className="Headline">
                Wetter in {weather.forecast.city.name}
                {(this.state.favorites == null ||
                  !this.state.favorites.includes(
                    weather.forecast.city.name
                  )) && (
                  <div>
                    <button
                      type="button"
                      className="btn"
                      onClick={() => this.handleFavoriteButton()}
                      disabled={weather.state === "loading"}
                    >
                      zu Favoriten hinzufügen
                    </button>
                  </div>
                )}
              </h2>
              <ForecastTable forecast={weather.forecast} />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default App;
