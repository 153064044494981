/*
list.clouds
list.clouds.all Cloudiness, %
list.wind
list.wind.speed Wind speed. Unit Default: meter/sec, Metric: meter/sec, Imperial: miles/hour.
list.wind.deg Wind direction, degrees (meteorological)
list.rain
list.rain.3h Rain volume for last 3 hours, mm
list.snow
list.snow.3h Snow volume for last 3 hours
list.dt_txt
 */

const API_URL = "https://api.openweathermap.org/data/2.5";

class WeatherClient {
  units = "metric";

  constructor(appId) {
    this.appId = appId;
  }

  forecastForCoordinate(coords) {
    return fetch(
      API_URL +
        `/forecast?appid=${encodeURIComponent(this.appId)}&units=${
          this.units
        }&lat=${coords.latitude}&lon=${coords.longitude}`
    ).then(response =>
      response.ok ? response.json() : Promise.reject(response.statusText)
    );
  }

  forecastForCityName(city) {
    return fetch(
      API_URL +
        `/forecast?appid=${encodeURIComponent(this.appId)}&units=${
          this.units
        }&q=${encodeURIComponent(city)},de`
    ).then(response =>
      response.ok ? response.json() : Promise.reject(response.statusText)
    );
  }

  currentWeatherForCityName(city) {
    return fetch(
      API_URL +
        `/weather?appid=${encodeURIComponent(this.appId)}&units=${
          this.units
        }&q=${encodeURIComponent(city)},de`
    ).then(response =>
      response.ok ? response.json() : Promise.reject(response.statusText)
    );
  }

  weatherIconUrl(iconId) {
    return `https://openweathermap.org/img/w/${encodeURIComponent(iconId)}.png`;
  }
}

const appId = process.env.REACT_APP_OWM_APPID || "";
export default new WeatherClient(appId);
